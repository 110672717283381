import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import symbol from "../images/symbol.png"

//Style
import 'bootstrap/dist/css/bootstrap.css';
import '../style/yr1style.css';
import '../style/style.scss';
import { findDOMNode } from "react-dom"




class Blog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      value: "",
      state: false,
      post: false,
      error: false,
      loading: false,
    };
  }

  handleSubmit = (e) => {
    e.preventDefault(); 
    const key = localStorage.getItem('key') === this.state.value ? localStorage.getItem('key') : this.state.value;
    this.setState({loading: true})
    fetch('https://yr1press-api.firebaseapp.com/posts', {
      method: 'GET', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
        'key': key
      },
    }).then((resp) => {
      return resp.json()
    }).then((data) => {
      this.setState({
        post: data,
        loading: false
      },() => {
        localStorage.setItem('key', data.key)
      })
    }).catch(err => {
      this.setState({
        error: true,
        loading: false
      })
      localStorage.removeItem('key')
    })
  }

  stateHandler = () => { this.setState({state: true}) }
  
  render() {
    
    const { data } = this.props
    const { value, post, error, loading } = this.state
    const siteTitle = data.site.siteMetadata.title
    let hide = true;
    const header = (data) => (
      <div>
        <div className="navLogo">
          <img src={symbol} alt=""/>
        </div>
        <div className="container">
          <div className="col-md-7 col-12">
            <div style={{margin: '2rem 0'}} className="blog-header d-flex justify-content-between align-items-end flex-wrap">  
              {data && 
                <div>
                  <h1 className="typography-largeTag">{data.artist}</h1>
                  <h1 className="typography-largeTag">{data.release}</h1>
                </div>
              }
              {data && 
                <div>
                  <span className="text-uppercase">release date: {data.date}</span>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    )
    const renderRelease = (element) => {
      if (element) {
        hide = false;
        return(
          <li className="caseList-item" key={element.code}>
              <Link
                className="caseList-Link"
                style={{ boxShadow: `none` }}
                to={`${element.code}`}
              >
                <span className="caseList-tag">{element.code}</span> 
                <span className="caseList-title">{`${element.artist} - ${element.release}`}</span> 
              </Link>
          </li>
        ) 
      }
    }

    return (
      <Layout header={header()} location={this.props.location} title={siteTitle}>
        <SEO title="All releases" />
        <div className="contentWrap text-uppercase">
          <ul className="caseList">
              {post ? renderRelease(post) : null }
          </ul>
          {hide &&
            <form onSubmit={this.handleSubmit}>
                <div>
                  <p style={{textTransform: "uppercase"}}>ENTER release code</p>
                  <div className="flex-input">
                    <input value={value} onChange={(e) => this.setState({value: e.target.value})} type="password" />
                    <button type="submit">ENTER</button> 
                  </div>
                  <div className="mt-3">
                  {loading && <p style={{textTransform: "uppercase"}}>LOADING...</p>}   
                  {(error && !loading) && <p style={{textTransform: "uppercase"}}>WRONG release code, try again</p>}
                  </div>
                </div>
            </form>
          }
           
        </div>
      </Layout>
    )
  }
}

export default Blog

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
        }
      }
    }
  }
`
